import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/monkey/Projects/bkc-sajt/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Gračanička biblioteka je jedna od najstarijih kulturnih institucija na općini Gračanica,a jedno spada i među najstarije narodne biblioteke u Bosni i Hercegovini. Osnovana je između dva svjetska rata, davne 1924.godine.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column mdxType="Column">
Inicijatori za osnivanje biblioteke u Gračanici bili su Mustafa Kamarić i Hajrudin Bujkalić. Svojim aktivnim radom ubzo je privukla pažnju naprednih elemenata i postala nosilac cjelokupnog kulturnog života u gradu. Njena čitaoica sa dnevnom štampom bila je jedina u ovoj okolini. 1927.godine dobila je ime Narodna biblioteka i tada je imala oko 2.500 knjiga što je za ono vrijeme bila prava rijetkost i u mnogo većim mjestima. Zanimljivi su podaci da je dolaskom električne energije u Gračanicu 1930.godine, u čitaonici biblioteke proradio prvi radio aparat u gradu, te da je biblioteka bila osnivač i kina 1939.godine gdje su prikazani prvi nijemi filmovi.
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column mdxType="Column">
Intenzivniji razvoj biblioteke u Gračanici počeo je 1962.godine većom nabavkom knjižnog fonda i proširenjem bibliotečke djelatnosti po mjesnim zajednicama, radnim organizacijama kao i brojem uposlenih. Svoj uspon biblioteka dostiže 1971.godine kada je vođena akcija “jedna knjiga na jednog stanovnika”. 1980.godine biblioteka je dobila i namjenski prostor u novoizgrađenom Domu kulture pa je posjedovala i dvije čitaonice sa više primjeraka novina i časopisa. Pred sam rat (1992-1995) biblioteka je imala oko 60.000 knjiga, te pored dva odjeljenja u gradskoj biblioteci imala i istureno odjeljenje u Bosanskom Petrovom selu i pokretnu biblioteku. Agresijom na BiH poremećen je rad i ove ustanove time što je veliki dio knjižnog fonda stradao i samim materijalnim statusom ove institucije kako u ratu tako i u poratnim godinama. Danas Narodna biblioteka djeluje u sastavu JU BKC Gračanica i nosi ime jednog od svojih osnivača “dr. Mustafa Kamarić”.
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      